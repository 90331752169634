import classnames from 'classnames';
import React, { useState } from 'react';

import { IComponent, IComponentProps } from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity, ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { MandsModal } from '../custom-components/mands-modal';
export interface IAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct }> {
    className?: string;
    addToCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;
    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
}

export declare type ICartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED';
export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;

    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IAddtoCartComponent extends IComponent<IAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps) => void;
}

const onClick = async (
    _event: React.MouseEvent<HTMLElement>,
    props: IAddToCartComponentProps,
    setDisabled: (disabled: boolean) => void,
    setAddtoCartModal: (disabled: boolean) => void,
    setProduct: (product: SimpleProduct) => void,
    setCheckoutUrl: (checkoutUrl: string) => void,
    setQuantity: (quantity: number) => void
): Promise<void> => {
    const cartError = addToCartError(props);
    let productToAdd = props.data.product;

    if (cartError) {
        propogateError(props, cartError);
        return;
    }

    setDisabled(true);

    if (!(props.getSelectedProduct === undefined)) {
        productToAdd = (await props.getSelectedProduct) || props.data.product;
    }

    const cartState = await getCartState(props.context.actionContext);
    await cartState
        .addProductToCart({
            product: productToAdd,
            count: props.quantity,
            availableQuantity: props.productAvailability?.AvailableQuantity,
            enableStockCheck: props.context.app.config.enableStockCheck
        })
        .then(result => {
            if (result.status === 'SUCCESS') {
                if (props.navigationUrl) {
                    const quantity = props.quantity ? props.quantity : 0;
                    setCheckoutUrl(props.navigationUrl);
                    setProduct(productToAdd);
                    setQuantity(quantity);
                    setAddtoCartModal(true);
                    setDisabled(false);
                    // window.location.assign(props.navigationUrl);
                }
                propogateResult(props, result);
            } else {
                propogateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: result });
                setDisabled(false);
            }
        });
};

const AddToCartComponentActions = {
    onClick: onClick
};

const AddToCart: React.FC<IAddToCartComponentProps> = (props: IAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);
    const [isAddToCartModalOpen, setAddtoCartModal] = useState(false);
    const [product, setProduct] = useState<SimpleProduct>();
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [quantity, setQuantity] = useState(0);
    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        return AddToCartComponentActions.onClick(event, props, setDisabled, setAddtoCartModal, setProduct, setCheckoutUrl, setQuantity);
    };
    return (
        <>
            <MandsModal
                isOpen={isAddToCartModalOpen}
                modalTitle={addToCartModalTitle(quantity, setAddtoCartModal)}
                modalBody={addToCartModalBody(product, quantity)}
                modalFooter={addToCartModalFooter(checkoutUrl, setAddtoCartModal)}
                className='add-to-cart-modal'
            />
            <button
                className={classnames('msc-add-to-cart ', props.className)}
                aria-label={getLinkText(props)}
                onClick={onClickHandler}
                disabled={props.disabled || disabled || shouldShowOutOfStock(props, true)}
            >
                {getLinkText(props)}
            </button>
        </>
    );
};

// Set default props
AddToCart.defaultProps = {
    quantity: 1
};

const addToCartModalTitle = (quantity: number, setAddtoCartModal: (disabled: boolean) => void): JSX.Element => {
    const closeModal = () => {
        setAddtoCartModal(false);
    };

    return (
        <div className='addtocart-heading'>
            <span className='addtocart-subheading'>Great Choice!</span> {quantity} successfully added to bag
            <div className='add-to-cart-modal-title' role='button' />
            <span role='button' onClick={closeModal} className='addtocart-cross' />
        </div>
    );
};

const addToCartModalBody = (product: SimpleProduct | undefined, quantity: number): JSX.Element => {
    const productName = product && product.Name ? product.Name : '';
    const dimensions = product && product.Dimensions ? product.Dimensions : undefined;
    // Dimension type value 3 is for Size
    const sizeDimensionsIndex = product && product.Dimensions ? product.Dimensions.findIndex(dim => dim.DimensionTypeValue === 3) : -1;
    const price = product && product.Price ? product.Price.toFixed(2) : undefined;
    const productImage = product && product.PrimaryImageUrl ? product.PrimaryImageUrl : '';
    return (
        <div className='add-to-cart-modal-body'>
            {productImage && <img src={productImage} alt='product-image' />}
            <div className='addtocart-product-info'>
                {productName && <span className='product-name'>{productName}</span>}
                <div>
                    {price && <span className='product-price'>£{price}</span>}
                    <span className='modal-seprator'> / </span>
                    {sizeDimensionsIndex !== -1 && dimensions && (
                        <span className='product-size'>{dimensions[sizeDimensionsIndex].DimensionValue?.Value}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

const addToCartModalFooter = (checkoutUrl: string, setAddtoCartModal: (disabled: boolean) => void): JSX.Element => {
    const closeModal = () => {
        setAddtoCartModal(false);
    };

    const navigateToCart = () => {
        window.location.assign(checkoutUrl);
    };
    return (
        <div className='add-to-cart-modal-footer'>
            <button onClick={closeModal} className='msc-btn buybox-continue-shopping'>
                Continue Shopping
            </button>
            <button onClick={navigateToCart} className='msc-btn buybox-checkout'>
                Checkout
            </button>
            <div className='modal-closer' onClick={closeModal} role='button' />
        </div>
    );
};

const getLinkText = (props: IAddToCartComponentProps): string => {
    if (!shouldShowOutOfStock(props, false)) {
        return props.addToCartText;
    }

    return props.outOfStockText;
};

const addToCartError = (props: IAddToCartComponentProps): IAddToCartFailureResult | undefined => {
    if (!props.data || !props.data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (props.data.product.Dimensions) {
        const missingDimensions = props.data.product.Dimensions.filter(
            dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value)
        );

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (props.productAvailability && props.productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity - props.context.app.config.outOfStockThreshold, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (props.context.app.config.enableStockCheck === false) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }

    // if (props.productAvailability && props.productAvailability.AvailableQuantity !== undefined && props.productAvailability.AvailableQuantity >= parseInt(props.context.app.config.outOfStockThreshold, 10) + (includeCurrentQuantity && props.quantity ? props.quantity : 1)) {
    //     return false;
    // } else {
    //     // Out of stock
    //     return true;
    // }

    const outOfStockThresholdLocal: number = props.context.app.config.outOfStockThreshold
        ? props.context.app.config.outOfStockThreshold
        : 0;

    const currentQuantity: number = includeCurrentQuantity && props.quantity ? props.quantity : 1;

    if (
        props.productAvailability &&
        props.productAvailability.AvailableQuantity !== undefined &&
        props.productAvailability.AvailableQuantity >= outOfStockThresholdLocal + currentQuantity
    ) {
        return false;
    } else {
        // Out of stock
        return true;
    }
};

const propogateResult = (props: IAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propogateError = (props: IAddToCartComponentProps, result: IAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

export default AddToCart;
