import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import React from 'react';

export interface IMandsModalProps {
    isOpen: boolean;
    modalTitle?: JSX.Element | string;
    modalBody?: JSX.Element | string;
    modalFooter?: JSX.Element | string;
    className: string;
}

export const MandsModal: React.FC<IMandsModalProps> = props => {
    return (
        <div>
            <Modal
                autoFocus={true}
                applicationNode={'renderPage'}
                isOpen={props.isOpen}
                className={`msc-mands-modal ${props.className}`}
                zIndex={1050}
            >
                {props.modalTitle && <ModalHeader> {props.modalTitle}</ModalHeader>}
                {props.modalBody && <ModalBody className='msc-mands-modal-body'>{props.modalBody}</ModalBody>}
                {props.modalFooter && <ModalFooter>{props.modalFooter}</ModalFooter>}
                <div className='inner' />
            </Modal>
        </div>
    );
};
