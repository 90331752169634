import * as React from 'react';
import { Button } from '@msdyn365-commerce-modules/utilities';

import { IFaqPageData } from './faq-page.data';
import { IFaqPageProps } from './faq-page.props.autogenerated';
import { RichTextComponent, RichText } from '@msdyn365-commerce/core';

export interface ITabsState {
    activeTab: number;
    selectedTab: number;
}
/**
 *
 * tabs component
 * @extends {React.PureComponent<IFaqPageProps<IFaqPageData>, ITabsState>}
 */
class FaqPage extends React.PureComponent<IFaqPageProps<IFaqPageData>, ITabsState> {
    constructor(props: IFaqPageProps<IFaqPageData>) {
        super(props);
        this.state = {
            activeTab: -1,
            selectedTab: -1
        };
    }
    public resetTabLabel = (label: string | null): string | undefined => {
        if (label !== null) {
            if (label.toLowerCase().includes('delivery')) {
                return 'About Delivery';
            } else if (label.toLowerCase().includes('returns') || label.toLowerCase().includes('refunds')) {
                return 'Returns and Refunds';
            } else if (label.toLowerCase().includes('payment')) {
                return 'Payment';
            } else if (label.toLowerCase().includes('service')) {
                return 'About the Service';
            } else if (label.toLowerCase().includes('contact')) {
                return 'Contact Us';
            } else if (label.toLowerCase().includes('returning')) {
                return 'Returning Customers';
            } else if (label.toLowerCase().includes('other')) {
                return `FAQ's`;
            } else {
                return '';
            }
        }
        return undefined;
    };
    public getSelectedLabel = () => {
        const {
            props: {
                config: { tabLabels }
            }
        } = this;
        const urlParams = new URLSearchParams(window.location.search);
        const _selectedTabLabel: string | null = urlParams.get('tab');
        if (_selectedTabLabel !== '' || _selectedTabLabel !== null || _selectedTabLabel !== undefined) {
            const selectedTabLabel = this.resetTabLabel(_selectedTabLabel);
            tabLabels &&
                tabLabels.map((tabLabel: React.ReactNode, index: number) => {
                    if (selectedTabLabel === tabLabel && selectedTabLabel !== undefined) {
                        this.setState({ selectedTab: index });
                        return;
                    }
                });
        }
    };
    public render() {
        const {
            props: {
                config: { heading, tabLabels, tabDescriptions }
            },
            state: { activeTab, selectedTab }
        } = this;
        const mobileView =
            this.props.context.request && this.props.context.request.device && this.props.context.request.device.Type === 'Mobile'
                ? true
                : false;
        if (selectedTab !== -2) {
            this.getSelectedLabel();
        }
        return (
            <div className='row msc-faq-page tabs'>
                {selectedTab === -1 && (
                    <div className='heading col-12 visible-tab-down'>
                        <h3>
                            <strong>{heading}</strong>
                        </h3>
                    </div>
                )}
                <div className='col-lg-3'>
                    <div className='msc-faq-page_tab-labels'>
                        <ul className='tab-list'>
                            {tabLabels &&
                                tabLabels.map((tabLabel: React.ReactNode, index: number) => {
                                    return (
                                        <li key={index}>
                                            <Button
                                                className={`tab-list-item ${
                                                    activeTab === index || (selectedTab >= 0 && selectedTab === index)
                                                        ? 'tab-list-active'
                                                        : ''
                                                }`}
                                                onClick={this.onClickTab(index)}
                                                role='tab'
                                                key={index}
                                            >
                                                {tabLabel}
                                            </Button>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
                <div className='col-lg-9'>
                    {activeTab === -1 && selectedTab === -1 && !mobileView ? (
                        <div className='msc-faq-pag heading'>
                            <h3>
                                <strong>{heading}</strong>
                            </h3>
                        </div>
                    ) : activeTab === -1 && selectedTab !== -1 ? (
                        <div className='msc-faq-page_tab-content'>
                            {tabDescriptions && tabDescriptions[selectedTab] && <RichTextComponent text={tabDescriptions[selectedTab]} />}
                        </div>
                    ) : (
                        <div className='msc-faq-page_tab-content'>
                            {tabDescriptions &&
                                tabDescriptions.map((tabDescription: RichText | undefined, i: number) => {
                                    if (activeTab !== i) {
                                        return undefined;
                                    }
                                    return tabDescription && <RichTextComponent text={tabDescription} />;
                                })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    private onClickTab = (index: number) => {
        return () => this.setState({ activeTab: index, selectedTab: -2 });
    };
}

export default FaqPage;
